<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Picnic Booking</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Dashboard</router-link>
              </li>
              <li class="breadcrumb-item active">Picnic Booking</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section>
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="row mb-1">
                  <div class="d-flex col-12">
                    <div class="float-start col-6"></div>
                    <div class="float-end col-6">
                      <router-link
                        :to="{ name: 'booking' }"
                        class="btn btn-gradient-primary float-end"
                      >
                        Back
                      </router-link>
                    </div>
                  </div>
                </div>
                <section>
                  <div class="row">
                    <div class="col-12">
                      <div class="card">
                        <div class="flex-grow-1">
                          <div class="p-1 sectionBlock" id="section-block">
                            <div class="row">
                              <div class="col-sm-12 col-lg-6">
                                <div class="mb-1">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Check in date</label
                                  >
                                  <Datepicker
                                    type="date"
                                    @closed="dateChange()"
                                    @cleared="resetForm()"
                                    :min="
                                      new Date().toISOString().substr(0, 10)
                                    "
                                    v-model="form.rangeDate"
                                    format="dd-MM-yyyy"
                                    value-format="dd-MM-yyyy"
                                    auto-apply
                                    placeholder="Check in date"
                                  />
                                </div>
                                <div class="mb-1" v-if="form.rangeDate">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Picnic Package</label
                                  >
                                  <Select2
                                    v-model="form.event_id"
                                    :options="options"
                                    :settings="{
                                      placeholder: 'Choose Package',
                                    }"
                                    :onSelect="eventSelect"
                                  />
                                  <div
                                    class="invalid-feedback fw-bold fst-italic"
                                    :style="{ display: 'none' }"
                                  >
                                    Please Choose Package.
                                  </div>
                                </div>
                                <div
                                  class="row num_slider mt-3 px-1"
                                  v-if="form.event_id"
                                >
                                  <div class="col-sm-0 col-lg-3"></div>
                                  <div class="col-sm-4 col-lg-3 title">
                                    <label
                                      class="form-label"
                                      for="basic-addon-name"
                                      >Adults (12+ Years)</label
                                    >
                                  </div>
                                  <div class="col-sm-4 col-lg-3 title">
                                    <label
                                      class="form-label"
                                      for="basic-addon-name"
                                      >₹{{
                                        event_selected.adult_price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      }}.00 + (Taxes)</label
                                    >
                                  </div>
                                  <div class="col-sm-1 col-lg-1 num_btn">
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-danger"
                                      @click="adultDecrement"
                                    >
                                      -
                                    </button>
                                  </div>
                                  <div class="col-sm-1 col-lg-1 num_val">
                                    {{ form.total_adult }}
                                  </div>
                                  <div class="col-sm-1 col-lg-1 num_btn">
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-success"
                                      @click="adultIncrement"
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                                <div
                                  class="row num_slider mt-1 px-1"
                                  v-if="form.event_id && form.total_adult > 0"
                                >
                                  <div class="col-sm-0 col-lg-3"></div>
                                  <div class="col-sm-4 col-lg-3 title">
                                    <label
                                      class="form-label"
                                      for="basic-addon-name"
                                      >Childs (5 - 12 Years)</label
                                    >
                                  </div>
                                  <div class="col-sm-4 col-lg-3 title">
                                    <label
                                      class="form-label"
                                      for="basic-addon-name"
                                      >₹{{
                                        event_selected.child_price
                                          .toString()
                                          .replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      }}.00 + (Taxes)</label
                                    >
                                  </div>
                                  <div class="col-sm-1 col-lg-1 num_btn">
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-danger"
                                      @click="childDecrement"
                                    >
                                      -
                                    </button>
                                  </div>
                                  <div class="col-sm-1 col-lg-1 num_val">
                                    {{ form.total_child }}
                                  </div>
                                  <div class="col-sm-1 col-lg-1 num_btn">
                                    <button
                                      type="button"
                                      class="btn btn-sm btn-success"
                                      @click="childIncrement"
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-6 mt-2">
                                <div class="mb-1">
                                  <div class="row">
                                    <div class="col-6">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Amount</label
                                      >
                                      <input
                                        class="form-control"
                                        :value="
                                          showCalc.price >= 1
                                            ? showCalc.price
                                            : ''
                                        "
                                        placeholder="Amount"
                                        readonly
                                      />
                                    </div>
                                    <div class="col-6">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Extra Charges</label
                                      >
                                      <input
                                        class="form-control"
                                        :value="
                                          showCalc.extra_person_charge >= 1
                                            ? showCalc.extra_person_charge
                                            : ''
                                        "
                                        placeholder="Extra Charges"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="mb-1">
                                  <div class="row">
                                    <div class="col-6">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Discount Amount</label
                                      >
                                      <!-- <input
                                        readonly
                                        class="form-control"
                                        :value="
                                          showCalc.coupon_amount >= 1
                                            ? showCalc.coupon_amount
                                            : ''
                                        "
                                        id="coupon_amount"
                                        placeholder="Discount Amount"
                                      /> -->
                                      <input
                                        class="form-control"
                                        v-model="bookingResult.coupon_amount"
                                        id="coupon_amount"
                                        placeholder="Discount Amount"
                                      />
                                    </div>
                                    <div class="col-6">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >18% GST INC.</label
                                      >
                                      <input
                                        class="form-control"
                                        :value="
                                          showCalc.gst_tax >= 1
                                            ? parseFloat(
                                                showCalc.gst_tax
                                              ).toFixed(2)
                                            : ''
                                        "
                                        placeholder="GST Amount"
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="mb-1">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Final Amount</label
                                  >
                                  <input
                                    class="form-control"
                                    :value="
                                      showCalc.final_total >= 1
                                        ? showCalc.final_total
                                        : ''
                                    "
                                    placeholder="Final Amount"
                                    readonly
                                  />
                                </div>
                                <div class="mb-1">
                                  <div class="row">
                                    <div class="col-12">
                                      <!-- <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Coupon Code</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="coupon_code"
                                        v-model="form.coupon_code"
                                        placeholder="Coupon Code"
                                      /> -->
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Discount Message</label
                                      >
                                      <textarea
                                        :readonly="!bookingResult.coupon_amount"
                                        type="text"
                                        class="form-control"
                                        name="coupon_code"
                                        v-model="form.discount_message"
                                        placeholder="Discount Message"
                                      ></textarea>
                                    </div>
                                    <!-- <div class="col-9">
                                      <label
                                        class="form-label"
                                        for="basic-addon-name"
                                        >Coupon Code</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="coupon_code"
                                        v-model="form.coupon_code"
                                        placeholder="Coupon Code"
                                      />
                                    </div> -->
                                    <!-- <div class="col-3">
                                      <button
                                        type="button"
                                        class="btn btn-primary applybtn"
                                        @click="applyCoupanCode()"
                                        :disabled="
                                          form.check_in_date == '' &&
                                          form.check_out_date == ''
                                        "
                                      >
                                        Apply
                                      </button>
                                    </div> -->
                                  </div>
                                </div>
                                <div class="mb-1">
                                  <label
                                    class="form-label"
                                    for="basic-addon-name"
                                    >Payment Mode</label
                                  >
                                  <Select2
                                    v-model="form.payment_mode_id"
                                    :options="$store.state.paymentModeData"
                                    :settings="{
                                      placeholder: 'Choose Payment Mode',
                                    }"
                                  />
                                  <div
                                    class="invalid-feedback fw-bold fst-italic"
                                    :style="{ display: 'none' }"
                                  >
                                    Please Choose Payment Mode.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="p-1 mt-2 sectionBlock"
                            v-if="active_persons"
                          >
                            <div class="row">
                              <div class="col-12">
                                <div
                                  class="invoice-padding invoice-product-details"
                                >
                                  <div class="source-item">
                                    <div data-repeater-list="group-a">
                                      <div
                                        v-for="(item, index) in form.persons"
                                        :key="index"
                                      >
                                        <div
                                          :class="
                                            index === 0
                                              ? `border-bottom sectionBlock mb-1 pt-1 pb-1`
                                              : index === 1
                                              ? `selectioBoxOther pt-1`
                                              : ``
                                          "
                                          class="d-flex row"
                                        >
                                          <h4 v-show="index === 0">
                                            Primary Member Detail
                                          </h4>
                                          <h4 v-show="index === 1">
                                            Other Member Detail
                                          </h4>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >First Name</label
                                              >
                                              <input
                                                type="text"
                                                class="form-control"
                                                name="first_name"
                                                placeholder="First Name"
                                                v-model="
                                                  form.persons[index].first_name
                                                "
                                              />
                                              <div
                                                class="fw-bold fst-italic error"
                                                v-show="firstError[index]"
                                              >
                                                Please enter first name.
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Middle Name</label
                                              >
                                              <input
                                                type="text"
                                                class="form-control"
                                                name="middle_name"
                                                placeholder="Middle Name"
                                                v-model="
                                                  form.persons[index]
                                                    .middle_name
                                                "
                                              />
                                              <!-- <div class="fw-bold fst-italic error" > Please enter Middle name. </div> -->
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Last Name</label
                                              >
                                              <input
                                                type="text"
                                                class="form-control"
                                                name="last_name"
                                                placeholder="Last Name"
                                                v-model="
                                                  form.persons[index].last_name
                                                "
                                              />
                                              <div
                                                class="fw-bold fst-italic error"
                                                v-show="lastError[index]"
                                              >
                                                Please enter last name.
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Mobile</label
                                              >
                                              <input
                                                type="number"
                                                class="form-control"
                                                name="mobile"
                                                v-model="
                                                  form.persons[index].mobile
                                                "
                                                id="mobile"
                                                placeholder="Mobile"
                                              />
                                              <div
                                                class="fw-bold fst-italic error"
                                                v-show="mobileError[index]"
                                              >
                                                Please enter mobile no.
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-2 col-sm-6">
                                            <div class="mb-1">
                                              <label
                                                class="form-label"
                                                for="basic-addon-name"
                                                >Id Proof</label
                                              >
                                              <input
                                                type="file"
                                                class="form-control"
                                                name="id_proof"
                                                ref="proofFile"
                                                placeholder="Id Proof"
                                                :id="index"
                                                @change="
                                                  uploadProofImage(
                                                    index,
                                                    $event
                                                  )
                                                "
                                              />
                                            </div>
                                          </div>
                                          <div
                                            class="col-md-1 col-sm-6 img-view"
                                            :class="
                                              form.persons[index].id_tmp_proof
                                                ? ``
                                                : `border`
                                            "
                                          >
                                            <div id="user-avatar-section">
                                              <div
                                                id="d-flex justify-content-start"
                                              >
                                                <img
                                                  v-if="
                                                    form.persons[index]
                                                      .id_tmp_proof
                                                  "
                                                  :src="
                                                    form.persons[index]
                                                      .id_tmp_proof
                                                  "
                                                  class="img-fluid rounded"
                                                  height="70"
                                                  width="70"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            class="col-md-1 col-sm-6 trash-view"
                                          >
                                            <div
                                              class="d-flex"
                                              v-if="index !== 0"
                                            >
                                              <vue-feather
                                                type="trash-2"
                                                size="20"
                                                @click="
                                                  removeContent(
                                                    index,
                                                    form.persons[index]
                                                      .detail_id
                                                  )
                                                "
                                              ></vue-feather
                                              >&nbsp;&nbsp;
                                            </div>
                                            <!-- <span
                                              class="badge badge-light-primary rounded-pill float-end"
                                              >{{ index + 1 }}</span
                                            > -->
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="row mt-1"
                                      v-show="
                                        form.persons.length < form.total_adult
                                      "
                                    >
                                      <div class="col-12">
                                        <button
                                          type="button"
                                          class="btn btn-primary btn-sm btn-add-new float-end"
                                          @click="addToContent()"
                                          data-repeater-create
                                        >
                                          <vue-feather
                                            type="plus"
                                            size="15"
                                          ></vue-feather>
                                          <span class="align-middle"
                                            >Add Person</span
                                          >
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2" v-if="form.check_in_date">
                            <div class="col-12 text-center">
                              <!-- <button
                                type="submit"
                                class="btn btn-primary me-1 data-submit"
                                :disabled="disabled"
                                v-show="active_booking && !payurl"
                                @click="bookVilla()"
                              > -->
                              <button
                                type="submit"
                                class="btn btn-primary me-1 data-submit"
                                :disabled="disabled"
                                v-show="!payurl"
                                @click="bookVilla()"
                              >
                                Book Now
                              </button>
                              <button
                                type="reset"
                                :disabled="disabled"
                                @click="resetForm()"
                                class="btn btn-outline-secondary"
                                data-bs-dismiss="modal"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import Select2 from "vue3-select2-component";

export default {
  name: "BookingAdd",
  components: {
    Datepicker,
    Select2,
  },
  data() {
    return {
      form: new Form({
        id: null,
        event_id: null,
        rangeDate: "",
        check_in_date: "",
        check_out_date: "",
        coupon_amount: "",
        coupon_code: "",
        discount_message: "",
        payment_mode_id: "",
        days: 0,
        total_adult: 0,
        total_child: 0,
        total_person: 0,
        persons: [
          {
            detail_id: "",
            user_id: "",
            barcode_id: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            mobile: "",
            id_proof: "",
            id_tmp_proof: "",
            is_primary: true,
            user_type: "Guest",
          },
        ],
      }),
      bookingResult: {
        price: 0,
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
        coupon_amount: 0,
      },
      events: [],
      options: [],
      event_selected: {},
      lastError: [],
      firstError: [],
      mobileError: [],
      totalAdultError: "none",
      discount_message_error: false,
      errors: {},
      token: localStorage.getItem("auth_token"),
      loader: false,
      disabled: false,
      payurl: null,
    };
  },
  beforeCreate() {
    var element = document.querySelector(".modal-backdrop");
    if (element) {
      element.remove();
    }
    var element2 = document.querySelector(".modal-open");
    if (element2) {
      element2.style.overflow = "unset";
    }
  },
  async mounted() {
    this.$store.dispatch("getPaymentModeList");
  },
  methods: {
    resetForm() {
      this.form.rangeDate = "";
      this.form.check_in_date = "";
      this.form.check_out_date = "";
      this.form.payment_mode_id = "";
      this.form.event_id = null;
      this.event_selected = {};
      this.form.persons = [];
      this.form.persons.push({
        detail_id: "",
        user_id: "",
        barcode_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        mobile: "",
        id_proof: "",
        id_tmp_proof: "",
        is_primary: true,
        user_type: "Guest",
      });
      this.bookingResult = {
        price: 0,
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
        coupon_amount: 0,
      };
    },
    async getEvents() {
      this.events = [];
      this.options = [];
      await axios
        .get(process.env.VUE_APP_API_URL + "/packages")
        .then((response) => {
          this.events = response.data;
          response.data.data.forEach((element) => {
            this.options.push({
              id: element.id,
              text: element.pack_name,
            });
          });
        });
    },
    adultIncrement() {
      this.form.total_adult++;
      this.totalPerson();
    },
    adultDecrement() {
      if (this.form.total_adult > 1) {
        this.form.total_adult--;
      }
      this.totalPerson();
    },
    childIncrement() {
      this.form.total_child++;
      this.totalPerson();
    },
    childDecrement() {
      if (this.form.total_child > 0) {
        this.form.total_child--;
      }
      this.totalPerson();
    },
    totalPerson() {
      this.form.total_person =
        parseInt(this.form.total_adult) + parseInt(this.form.total_child);
      this.getTotalPrice();
    },
    eventSelect() {
      this.form.total_adult = 1;
      this.form.total_child = 0;
      this.event_selected = this.events.data.find(
        (x) => x.id == this.form.event_id
      );

      if (this.event_selected) {
        this.event_selected.gst = 0;
        this.form.gst_tax_percent = 0;
        this.bookingResult.gst_tax_percent = 0;
      }
      this.totalPerson();
      this.checkAvailability();
    },
    getTotalPrice() {
      var adult_price = this.event_selected.adult_price
        ? parseFloat(this.event_selected.adult_price)
        : parseFloat(0);
      var child_price = this.event_selected.child_price
        ? parseFloat(this.event_selected.child_price)
        : parseFloat(0);

      var total_price = 0;
      total_price += adult_price * parseInt(this.form.total_adult);
      total_price += child_price * parseInt(this.form.total_child);

      this.bookingResult.price = total_price;
    },
    dateChange() {
      this.dateResult();
      if (this.form.rangeDate) {
        this.getEvents();
      }
      this.checkAvailability();
    },
    async checkAvailability() {
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/check-availability",
          {
            booking_type: 'OneDayPicnic',
            from_date: this.form.check_in_date,
            to_date: this.form.check_out_date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          var $this = this;

          response.data.data.forEach(function (item) {
            if (item.booking_type == "PicnicBooking") {
              if (
                moment(item.date).format("YYYY-MM-DD") ==
                $this.form.check_in_date
              ) {
                if (
                  item.for_picnic == "All" ||
                  parseInt(item.for_picnic) ===
                    parseInt($this.form.event_id)
                ) {
                  if($this.form.event_id){
                    $this.form.rangeDate = "";
                    $this.form.check_in_date = "";
                    $this.form.check_out_date = "";
                    $this.$toast.error("Booking not available on this date.");
                    $this.form.event_id = null;
                    return false;
                  }
                  
                  
                }
              }
            }
          });
        })
        .catch((e) => {
          console.log("e: ", e);
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          const start_date = moment(date[0]).format("YYYY-MM-DD");
          const end_date = moment(date[1]).format("YYYY-MM-DD");
          this.form.check_in_date = start_date;
          this.form.check_out_date = end_date;

          const dateA = moment(end_date);
          const dateB = moment(start_date);
          this.form.days = dateA.diff(dateB, "days") + 1;
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
          this.form.days = 1;
        }
      }
    },
    addToContent() {
      this.form.persons.push({
        detail_id: this.form.detail_id,
        user_id: this.form.user_id,
        barcode_id: this.form.barcode_id,
        first_name: this.form.first_name,
        middle_name: this.form.middle_name,
        last_name: this.form.last_name,
        mobile: this.form.mobile,
        id_proof: this.form.id_proof,
        id_tmp_proof: this.form.id_tmp_proof,
        is_primary: false,
        user_type: "Guest",
      });
      this.form.persons.detail_id = null;
      this.form.persons.user_id = null;
      this.form.persons.barcode_id = null;
      this.form.persons.first_name = "";
      this.form.persons.middle_name = "";
      this.form.persons.last_name = "";
      this.form.persons.mobile = "";
      this.form.persons.id_proof = "";
      this.form.persons.id_tmp_proof = "";
      this.form.persons.is_primary = "";
      this.form.persons.user_type = "Guest";
    },
    removeContent(idx, detailId = null) {
      if (detailId) {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to Delete this User!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ms-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            axios
              .post(
                process.env.VUE_APP_API_URL + "/booking/villa/delete-user",
                {
                  detail_id: detailId,
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${this.token}`,
                  },
                }
              )
              .then((response) => {
                if (response.data.status) {
                  this.form.persons.splice(idx, 1);
                  this.disabled = false;
                } else {
                  this.$toast.error(response.data.error?.[0]);
                }
              })
              .catch((error) => {
                this.$toast.error(error);
              });
          }
        });
      } else {
        this.form.persons.splice(idx, 1);
        this.disabled = false;
      }
    },
    uploadProofImage(index, e) {
      let imgProof = e.target.files[0];
      if (imgProof.size / 1024 >= 400) {
        e.target.value = null;
        this.$toast.error("File too Big, please select a file less than 400Kb");
        return false;
      }
      this.createBase64Image(imgProof, index);
    },
    createBase64Image(fileObject, index) {
      const reader = new FileReader();
      reader.onload = (e) => {
        // const newImageEncode = e.target.result.split("base64,");
        // this.form.persons[index].id_tmp_proof = e.target.result;
        // this.form.persons[index].id_proof = newImageEncode[1];
        this.form.persons[index].id_tmp_proof = e.target.result;
        this.form.persons[index].id_proof = fileObject;
      };
      reader.readAsDataURL(fileObject);
    },
    async applyCoupanCode() {
      this.errors = {};

      await axios
        .post(
          process.env.VUE_APP_API_URL + "/coupon-code-admin",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            coupon_code: this.form.coupon_code,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            var res = response.data.data;
            var total =
              this.bookingResult.price +
              this.bookingResult.festival_charge +
              this.bookingResult.extra_person_charge;
            if (total > parseFloat(res.coupon_amount)) {
              this.bookingResult.coupon_amount = parseFloat(res.coupon_amount);
              this.$toast.success("Coupon Applied!");
            } else {
              this.$toast.warning("Coupon Invalid!");
            }
          } else if (response.data.code == 400) {
            this.$toast.warning(response.data.error?.[0]);
          } else if (
            response.data.code == 422 &&
            response.data.status == false
          ) {
            this.$toast.warning(response.data.message);
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    async bookVilla() {
      this.firstError = [];
      this.lastError = [];
      this.mobileError = [];
      for (var i = 0; i < this.form.persons.length; i++) {
        if (
          this.form.persons[i].first_name == undefined ||
          this.form.persons[i].first_name == null ||
          this.form.persons[i].first_name == ""
        ) {
          this.firstError[i] = true;
          return;
        }
        if (
          this.form.persons[i].last_name == undefined ||
          this.form.persons[i].last_name == null ||
          this.form.persons[i].last_name == ""
        ) {
          this.lastError[i] = true;
          return;
        }
        if (
          this.form.persons[i].mobile == undefined ||
          this.form.persons[i].mobile == null ||
          this.form.persons[i].mobile == ""
        ) {
          this.mobileError[i] = true;
          return;
        }
      }

      this.disabled = true;

      var apiUrl = process.env.VUE_APP_API_CUS_URL + "/villa-book";

      var newPersons = [];

      this.form.persons.forEach((element, index) => {
        delete element.id_tmp_proof;
        element.is_primary = element.is_primary == true ? 1 : 0;
        newPersons[index] = element;
      });

      await axios
        .post(
          apiUrl,
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            total_child: this.form.total_child,
            coupon_code: this.form.coupon_code ? this.form.coupon_code : null,
            discount_message: this.form.discount_message,
            coupon_amount: this.showCalc.coupon_amount,
            payment_mode_id: this.form.payment_mode_id,
            total: this.showCalc.total,
            extra_person_charge: this.showCalc.extra_person_charge,
            festival_charge: this.showCalc.festival_charge,
            gst_tax: this.showCalc.gst_tax_percent,
            gst_amount: 0,
            person: this.form.total_adult,
            sub_total: this.showCalc.sub_total,
            final_total: this.showCalc.final_total,
            villa_booking: [
              {
                villa_type_id: this.event_selected.id,
                person: this.form.total_adult,
              },
            ],
            persons: newPersons,
            type: "OneDayPicnic",
            id: this.event_selected.id
              ? parseInt(this.event_selected.id)
              : null,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.status) {
            this.payurl = null;

            if (this.form.payment_mode_id == 1) {
              this.$toast.success(response.data?.message);
              this.$router.push({ name: "booking" });
            } else {
              this.getPayLink(
                response.data.data.booking_id,
                response.data.data.user_id
              );
            }
          } else {
            this.$toast.error(response.data?.message);
          }

          setTimeout(() => {
            this.disabled = false;
          }, 500);
        });
    },
    async getPayLink(booking_id, user_id) {
      this.payurl = null;

      const formdata = new FormData();
      formdata.append("amount", this.showCalc.final_total);
      formdata.append("user_id", user_id);
      formdata.append("device", "ad");
      formdata.append("mobileNumber", this.form.persons[0].mobile);
      formdata.append("redirectMode", "REDIRECT");
      formdata.append("booking_id", booking_id);
      formdata.append("redirectUrl", process.env.VUE_APP_URL + "/booking");

      await axios
        .post(process.env.VUE_APP_API_CUS_URL + "/pay-online", formdata, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        // await axios.post("https://crm-server.riocolina.com/api/customer/pay-online", formdata)
        .then((response) => {
          if (response.data.links) {
            this.payurl = response.data.links.pay_link_web;
            window.location.href = response.data.links.pay_link_web;
          }
        });
    },
  },
  computed: {
    active_persons: function () {
      var active_status = false;
      if (this.form.check_in_date && this.showCalc.total) {
        if (this.form.payment_mode_id) {
          active_status = true;
        }
      }
      return active_status;
    },
    active_booking: function () {
      var active_status = false;
      if (this.active_persons) {
        if (this.form.persons.length == this.form.total_adult) {
          active_status = true;
        }
      }

      return active_status;
    },
    showCalc: function () {
      var var_price = 0;
      if (this.bookingResult.price) {
        var_price = parseFloat(this.bookingResult.price);
      }

      var var_extra_person_charge = 0;
      if (this.bookingResult.extra_person_charge) {
        var_extra_person_charge = parseFloat(
          this.bookingResult.extra_person_charge
        );
      }

      var var_festival_charge = 0;
      if (this.bookingResult.festival_charge) {
        var_festival_charge = parseFloat(this.bookingResult.festival_charge);
      }

      var var_coupon_amount = 0;
      if (this.bookingResult.coupon_amount) {
        var_coupon_amount = parseFloat(this.bookingResult.coupon_amount);
      }

      var var_gst_tax_percent = 0;
      if (this.bookingResult.gst_tax_percent) {
        var_gst_tax_percent = parseFloat(this.bookingResult.gst_tax_percent);
      }

      var var_gst_tax_amt = 0;
      if (var_gst_tax_percent) {
        var_gst_tax_amt =
          ((var_price +
            var_extra_person_charge +
            var_festival_charge -
            var_coupon_amount) *
            var_gst_tax_percent) /
          100;
      }

      var var_total = 0;
      if (var_price) {
        var_total = var_price;
      }

      var var_sub_total =
        var_price +
        var_extra_person_charge +
        var_festival_charge -
        var_coupon_amount;
      var var_final_total = var_sub_total + var_gst_tax_amt;

      return {
        price: var_price,
        total: var_total,
        festival_charge: var_festival_charge,
        extra_person_charge: var_extra_person_charge,
        sub_total: var_sub_total,
        coupon_amount: var_coupon_amount,
        gst_tax: var_price - var_price * (100 / (100 + 18)), //var_gst_tax_amt,
        gst_tax_percent: var_gst_tax_percent,
        final_total: var_final_total,
      };
    },
  },
};
</script>
<style>
.card-datatable {
  overflow-x: auto;
}

.product-box {
  border: 1px solid #ebe9f1;
  border-radius: 0.357rem;
}

.btn-add-new span {
  margin-top: 5px;
}

.btn-add-new {
  display: flex;
}

.vue-feather--plus {
  margin: 2px 3px 0px 0px;
}

.img-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.trash-view i {
  color: #fff;
  transition: all 0.2s ease;
  background-image: linear-gradient(47deg, #ea5455, #f08182);
  background-repeat: repeat;
  padding: 0.715rem 0.736rem;
  border-radius: 0.358rem;
  cursor: pointer;
}

.trash-view .disable {
  background-image: linear-gradient(47deg, #302a2a, #adabab) !important;
}

.trash-view i:hover {
  transform: translateY(-2px);
}

.sectionBlock {
  box-shadow: 6px 6px 25px -8px #9e9e9e;
}

.error {
  color: #ea5455;
  font-size: 0.857rem;
}

.form-check {
  padding-left: 0.785rem !important;
}

.status-badge {
  position: relative;
  cursor: pointer;
}

.statusDiv {
  position: absolute;
  box-shadow: 6px 6px 25px -8px #9e9e9e;
  padding: 5px;
  border-radius: 10px;
  z-index: 9999;
  background-color: #fff;
}

.statusDiv ul,
.mobileDiv ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0 !important;
}

.statusDiv ul li,
.mobileDiv ul li {
  padding: 5px;
  cursor: pointer;
}

.statusDiv ul li:hover,
.mobileDiv ul li:hover {
  background-color: #e3e0ff;
  font-weight: bold;
  border-radius: 5px;
}

.statusZindex {
  z-index: -1;
}

.selectioBoxOther {
  box-shadow: 2px -12px 16px -8px #dbdbdb;
}

.mobileSearchDiv {
  position: relative;
}

.mobileDiv {
  position: absolute;
  padding: 5px 10px;
  border-radius: 10px !important;
  z-index: 9999;
  top: 50px;
  height: auto;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
  transition: all 1s;
  background-color: #fff;
  box-shadow: 1px 12px 25px 4px #9e9e9e !important;
}

.search-mobile {
  border-right: 1px solid #d8d6de !important;
  border-top-right-radius: 0.357rem !important;
  border-bottom-right-radius: 0.357rem !important;
}

.search-mobile:focus {
  border-color: #7367f0 !important;
}

.wrapper-option {
  display: flex;
}

.applybtn {
  margin-top: 23px;
  width: 100%;
}

/* slider */
.num_slider .num_val {
  display: flex;
  justify-content: center;
  align-items: center;
}

.num_slider .title {
  display: flex;
  align-items: end;
}
/* end slider */

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
</style>
